import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { GarbageAreasImages } from "assets/services";

const GarbageAreas = () => {
  return (
    <ServicesChildPage
      title="Крытые мусорные площадки"
      description="Крытые мусорные площадки открытого и закрытого типа, установка кодового замка. Различные материалы для стен площадки: дерево, камень, профнастил, жалюзи и др. 3D проект."
      images={GarbageAreasImages}
      keywords="мусорные площадки, закрытые мусорные площадки"
    />
  );
};

export default GarbageAreas;
