const BicycleParkingContent = () => (
  <ul>
    <li>
      Изготовление велопарковок «под ключ»:
      <ul>
        <li>крытые</li>
        <li>отдельно стоящие</li>
        <li>П-образные</li>
        <li>треугольные</li>
        <li>с навесом</li>
      </ul>
    </li>
    <li>
      Использование различных материалов для стен: дерево, камень, профнастил, жалюзи и
      др.
    </li>
    <li>Создание 3D проекта</li>
  </ul>
);

export default BicycleParkingContent;
