import Page from "../Page";
import ChildPageHeader from "./ChildPageHeader";

export type ChildPageProps = {
  title: string;
  keywords?: string;
  description?: string;
  hideHeader?: boolean;
  children?: React.ReactNode;
}

const ChildPage = ({ title, keywords, description, hideHeader, children }: ChildPageProps) => (
  <Page title={title} keywords={keywords} description={description}>
    {!hideHeader && (
      <ChildPageHeader title={title} />
    )}
    {children}
  </Page>
);

export default ChildPage;
