import { HTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faViber } from "@fortawesome/free-brands-svg-icons";

type ViberIconProps = HTMLAttributes<HTMLAnchorElement> & {
  viberHref: string;
}

const ViberIcon = ({ viberHref, ...props }: ViberIconProps) => {

  return (
    <a {...props} href={viberHref} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faViber} size="lg" color="#665CAC" />
    </a>
  );
};

export default ViberIcon;
