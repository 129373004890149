import styled from "styled-components";
import { DefaultTheme } from "styled-components";
import { Link } from "react-router-dom";

type ColorKey = keyof DefaultTheme["colors"];

type LinkProps = {
  color: ColorKey;
}

const StyledAnchor = styled.a<LinkProps>`
  color: ${({ theme, color }) => theme.colors[color]};
  text-decoration: none;

  /* don't need to hide hover */
  :hover {
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;

const StyledRouterLink = styled(Link)<LinkProps>`
  color: ${({ theme, color }) => theme.colors[color]};
  text-decoration: none;

  /* don't need to hide hover */
  :hover {
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;

type StyledLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  color: ColorKey;
  to?: string;
  children: React.ReactNode;
}

const StyledLink = ({ children, color, to, ...props }: StyledLinkProps) => {
  if (to) {
    return <StyledRouterLink to={to} color={color} {...props}>{children}</StyledRouterLink>;
  }
  return <StyledAnchor color={color} {...props}>{children}</StyledAnchor>;
};

export default StyledLink;
