import { Container, Row, Col } from "react-bootstrap";
import { Line, List, ListItem } from "components";
import { WorkOrder } from "assets";
import workSteps from "./workSteps";

const WorkOrderSection = () => {
  return (
    <section id="work_order" className="pt-1 pt-sm-5 pb-4">
      <Container>
        <Row className="justify-content-center">
          <Col xl={6} lg={8}>
            <div className="title text-center">
              <h2 className="fw-bold">Порядок выполнения работ</h2>
              <Line />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-around align-items-center">
          <Col lg={6}>
            <List>
              {workSteps.map(({ number, text }) => (
                <ListItem key={number} number={number}>
                  {text}
                </ListItem>
              ))}
            </List>
          </Col>
          <Col lg={5} className="pt-4 pt-lg-0">
            <img className="img-fluid" src={WorkOrder} alt="Порядок выполнения работ" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WorkOrderSection;
