import styled from "styled-components";
import { firmData } from "data";

const Title = styled.h3`
  font-size: 18px;
  overflow: hidden;
  font-weight: 600;
`;

const MapFrame = () => (
  <>
    <Title className="text-center mb-3">Схема проезда</Title>
    <iframe
      title="Схема проезда"
      className="w-100"
      src={firmData.mapFrameUri}
      width="560"
      height="400"
    ></iframe>
  </>
);

export default MapFrame;
