const FencesContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска:
      <ul>
        <li>заборов любой сложности</li>
        <li>газонных ограждений</li>
        <li>кованных заборов</li>
      </ul>
    </li>
    <li>Монтаж 3D заборов </li>
    <li>Создание индивидуального проекта</li>
  </ul>
);

export default FencesContent;
