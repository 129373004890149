import { HTMLAttributes } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ListCircledIcon = styled.i.attrs(() => ({
  className: "rounded-circle",
}))`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
  font-size: 22px;
`;

type ListProps = HTMLAttributes<HTMLDivElement>;

const List = ({ children, ...props }: ListProps) => <div {...props}>{children}</div>;

type ListItemProps = {
  number: number;
  children: React.ReactNode;
}

const ListItem = ({ number, children, ...props }: ListItemProps) => {
  const icon: IconProp = `fa-${number}` as IconProp;

  return (
    <div className="d-flex align-items-center mb-2" {...props}>
      <div className="flex-shrink-0">
        <ListCircledIcon>
          <FontAwesomeIcon icon={icon} />
        </ListCircledIcon>
      </div>
      <div className="flex-grow-1 ms-3">{children}</div>
    </div>
  );
}
export { List, ListItem };
