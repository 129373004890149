const MebelLoftContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска мебели в стиле LOFT:
      <ul>
        <li>столы и обеденные группы</li>
        <li>стеллажи и полки для производства и для дома</li>
        <li>вешалки для магазина одежды</li>
        <li>кровати с кованными элементами</li>
      </ul>
    </li>
  </ul>
);

export default MebelLoftContent;
