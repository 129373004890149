import styled from "styled-components";

type BenefitCardContentProps = {
  textColor: string;
}

const BenefitCardContent = styled.div<BenefitCardContentProps>`
  color: ${({ textColor }) => textColor};
`;

export default BenefitCardContent;
