const GardenProductsContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска изделий для сада и дачи:
      <ul>
        <li>мангалы</li>
        <li>гамаки</li>
        <li>садовые качели</li>
        <li>беседки</li>
        <li>лавочки</li>
      </ul>
    </li>
  </ul>
);

export default GardenProductsContent;
