import "./App.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import { loadIcons } from "utils";
import { Theme } from "types";
import { MetaTags, ScrollToTop, AppRoutes, Header, Footer } from "components";

ReactGA.initialize("G-PWGQ09XSV0");
loadIcons();

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <div className="App">
          <MetaTags />
          <Router>
            <ScrollToTop />
            <Header />
            <AppRoutes />
            <Footer />
          </Router>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
