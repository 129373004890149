const IndustrialProductionContent = () => (
  <ul>
    <li>Сварка промышленного оборудования для производства</li>
    <li>Ремонт промышленного оборудования</li>
    <li>Изготовление ферм, конвейерных линий, емкостей</li>
    <li>Прокладка магистрали из черного металла и нержавеющей стали высокого давления</li>
    <li>Токарные работы</li>
    <li>Изготовление кованых изделий</li>
  </ul>
);

export default IndustrialProductionContent;
