import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Device } from "types";
import { Home1920, Home1220, Home640, Home920 } from "assets";

const Header = styled.header`
  height: 15vh;
  background-image: url(${Home1920});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.navbar};

  @media (max-width: ${Device.desktopL}) {
    background-image: url(${Home1220});
  }

  @media (max-width: ${Device.desktop}) {
    background-image: url(${Home920});
  }

  @media (max-width: ${Device.tablet}) {
    background-image: url(${Home640});
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
`;

type ChildPageHeaderProps = {
  title: string;
}

const ChildPageHeader = ({ title }: ChildPageHeaderProps) => {
  return (
    <Header>
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col className="col-12 text-center">
            <Title className="h2">{title}</Title>
          </Col>
        </Row>
      </Container>
    </Header>
  );
};

export default ChildPageHeader;