import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import FooterSocialIcon from "./FooterSocialIcon";
import { firmData, firmLinks } from "data";;

const contactInfo = [
  {
    href: firmData.mapUri,
    icon: faMapMarkerAlt,
    text: firmData.address,
  },
  {
    href: firmLinks.telHref,
    icon: faPhone,
    text: `${firmData.phoneNumberFormatted} (${firmData.phoneOperator})`,
  },
  {
    href: firmLinks.emailHref,
    icon: faEnvelope,
    text: firmData.email,
  },
];

const FooterContactInfoContainer = () => (
  <>
    {contactInfo.map(({ href, icon, text }) => (
      <div className="d-flex align-items-center mb-3" key={href}>
        <div className="flex-shrink-0">
          <FooterSocialIcon {...{ href }} rel="noreferrer" target="_blank">
            <FontAwesomeIcon {...{ icon }} />
          </FooterSocialIcon>
        </div>
        <div className="flex-grow-1 ms-3">{text}</div>
      </div>
    ))}
  </>
);

export default FooterContactInfoContainer;
