import styled from "styled-components";
import { Device } from "types";

const LogoSubtitle = styled.span`
  font-size: 24px;
  font-family: "Caveat", Arial, cursive;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  line-height: 24px;

  @media (max-width: ${Device.mobile}) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export default LogoSubtitle;
