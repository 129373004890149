import styled from "styled-components";

const FooterSocialIcon = styled.a.attrs({
  className: "rounded-circle",
})`
  font-size: 18px;
  display: inline-block;
  background: ${({ theme }) => theme.colors.socialIcon};
  color: ${({ theme }) => theme.colors.white};
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.primaryYellow};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      background: ${({ theme }) => theme.colors.socialIcon};
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
    }
  }
`;

export default FooterSocialIcon;
