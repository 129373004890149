import styled from "styled-components";
import { Col, Badge, Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Device } from "types";
import { Link } from "react-router-dom";

const TitleLink = styled(Link)`
  text-decoration: none;
  outline: none;
  color: ${(props) => props.theme.colors.black};

  :hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }

  @media (hover: none), (hover: on-demand) {
    text-decoration: underline;
  }

  @media (max-width: ${Device.tablet}) {
    text-decoration: underline;
  }
`;

const ServiceCard = styled(Card)`
  border: none;
`;

const ServiceCardImageBox = styled.div`
  overflow: hidden;
`;

const CardImageWithoutHover = styled.img`
  width: 100%;
  height: 30vh;
  object-fit: fill;
`;

const ServiceCardImage = styled(LazyLoadImage)`
  width: 100%;

  height: 30vh;
  max-height: 320px;
  object-fit: fill;

  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;

  @media (hover: hover) {
    :hover {
      opacity: 0.7;
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      -ms-transform: scale(1.15);
      -o-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
`;

const ServiceCardTitle = styled.h4`
  font-weight: bold;
`;

type CardProps = {
  placeholder: string;
  route?: string;
  imgSrc: string;
  title: string;
  badge?: string;
  children?: React.ReactNode;
};

const ServiceInfoCard = ({ placeholder, route, imgSrc, title, badge, children }: CardProps) => {
  const placeHolderImage = placeholder;
  return (
    <Col sm={6} md={6} lg={4} className="mb-4">
      <ServiceCard className="h-100">
        <ServiceCardImageBox className="shadow position-relative">
          {route ? (
            <Link to={route}>
              <ServiceCardImage
                className="card-img-top"
                src={imgSrc}
                alt={title}
                effect="blur"
                placeholderSrc={placeHolderImage}
              />
            </Link>
          ) : (
            <CardImageWithoutHover className="card-img-top" src={imgSrc} alt={title} />
          )}
          {badge && (
            <Badge bg="danger" className="position-absolute top-0 end-0 m-2">
              {badge}
            </Badge>
          )}
        </ServiceCardImageBox>
        <Card.Body>
          <ServiceCardTitle className="card-title text-center text-uppercase">
            {route ? <TitleLink to={route}>{title}</TitleLink> : title}
          </ServiceCardTitle>
          <Card.Text as="div">{children}</Card.Text>
        </Card.Body>
      </ServiceCard>
    </Col>
  );
};

export default ServiceInfoCard;
