import {
  Home,
  ContactsPage,
  ServicesPage,
  PartnershipPage,
  Fences,
  Stairs,
  Sheds,
  GarbageAreas,
  Gates,
  GardenProducts,
  MebelLoft,
  IndustrialProduction,
  BicycleParking,
  NotFoundPage
} from "pages";

const routes = [
  { path: "/", label: "Главная", showInNavbar: true, order: 0, component: Home },
  { path: "/services", label: "Услуги", showInNavbar: true, order: 1, component: ServicesPage },
  {
    path: "/partnership",
    label: "Сотрудничество",
    showInNavbar: true,
    order: 2,
    component: PartnershipPage,
  },
  { path: "/contacts", label: "Контакты", showInNavbar: true, order: 3, component: ContactsPage },
  { path: "/services/fences", component: Fences },
  { path: "/services/stairs", component: Stairs },
  { path: "/services/sheds", component: Sheds },
  { path: "/services/garbage-areas", component: GarbageAreas },
  { path: "/services/gates", component: Gates },
  { path: "/services/mebel-loft", component: MebelLoft },
  { path: "/services/industrial-production", component: IndustrialProduction },
  { path: "/services/bicycle-parking", component: BicycleParking },
  { path: "/services/garden-and-cottage-products", component: GardenProducts },
  { path: "*", component: NotFoundPage },
];

export default routes;
