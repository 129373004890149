import MainSection from "./sections/main/MainSection";
import AboutUsSection from "./sections/aboutUs/AboutUsSection";
import BenefitsSection from "./sections/BenefitsSection";
import WorkOrderSection from "./sections/WorkOrderSection";
import { Page } from "components";

const HomePage = () => {
  return (
    <Page title="Главная">
      <MainSection />
      <AboutUsSection />
      <BenefitsSection />
      <WorkOrderSection />
    </Page>
  );
};

export default HomePage;
