const GatesContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска всех видов ворот:
      <ul>
        <li>распашные</li>
        <li>откатные</li>
        <li>гаражные</li>
        <li>кованные</li>
      </ul>
    </li>
    <li>Изготовление, монтаж и покраска калиток любой сложности </li>
    <li>
      Использование любого материала: профильная труба, профнастил, металлический
      штакетник и др.
    </li>
  </ul>
);

export default GatesContent;
