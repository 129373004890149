import styled from "styled-components";

type BenefitsCardContainerProps = {
  cardColor: string;
  textColor: string;
}

const BenefitsCardContainer = styled.div<BenefitsCardContainerProps>`
  height: 100%;
  ${({ cardColor, textColor }) => `
      background: ${cardColor};
      color: ${textColor};`}
`;

export default BenefitsCardContainer;
