import styled from "styled-components";

type BenefitCardTitleProps = {
  textColor: string;
}

const BenefitCardTitle = styled.h5<BenefitCardTitleProps>`
  color: ${({ textColor }) => textColor};
  text-transform: uppercase;
  font-weight: bold;
`;

export default BenefitCardTitle;
