import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Button = styled.a`
  color: ${({ theme }) => theme.colors.white};

  :hover {
    color: ${({ theme }) => theme.colors.primaryYellow};
    background-color: transparent;
  }
`;

type PrimaryButtonProps = {
  className?: string;
  href?: string;
  to?: string;
  children: React.ReactNode;
};

const PrimaryButton = ({ className = "", children, href, to, ...props }: PrimaryButtonProps) => {
  const Component = to ? Link : 'a';

  return (
    <Button as={Component} {...props} className={`btn btn-lg btn-warning text-uppercase ${className}`} href={href} to={to}>
      {children}
    </Button>
  );
};

export default PrimaryButton;