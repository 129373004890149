import { Container, Row } from "react-bootstrap";
import { useCheckMobileDevice } from "hooks";
import { PrimaryButton } from "components";
import MainSectionHeader from "./MainSectionHeader";
import MainSectionHeaderTitle from "./MainSectionHeaderTitle";
import MainSectionHeaderSubTitle from "./MainSectionHeaderSubTitle";
import { firmLinks } from "data";

const MainSection = () => {
  const isMobile = useCheckMobileDevice();

  return (
    <MainSectionHeader>
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <div className="col-12 text-center">
            <MainSectionHeaderTitle>
              Cварочные работы <br className="br-1" /> любой сложности
              <br className="br-2" />в Бресте <br className="br-3" /> и Брестской области
            </MainSectionHeaderTitle>
            <MainSectionHeaderSubTitle className="text-center">От вас идея - от нас результат!</MainSectionHeaderSubTitle>
            {isMobile && <PrimaryButton href={firmLinks.telHref}>Связаться с нами</PrimaryButton>}
            {!isMobile && <PrimaryButton className="mt-3" to="/services">Узнать больше</PrimaryButton>}
          </div>
        </Row>
      </Container>
    </MainSectionHeader>
  );
};

export default MainSection;
