import styled from "styled-components";
import { Device } from "types";

const MainSectionHeaderSubTitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.4;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  font-size: 26px;
  line-height: 26px;
  font-family: "Caveat", Arial;
  font-style: cursive;
  font-weight: bold;

  @media (max-width: ${Device.mobileS}) {
    font-size: 22px;
    line-height: 22px;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 31px;
    line-height: 31px;
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 31px;
    line-height: 31px;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (min-width: ${Device.desktopL}) {
    font-size: 50px;
    line-height: 50px;
  }

  @media (min-width: ${Device.desktopXL}) {
    font-size: 56px;
    line-height: 56px;
  }
`;

export default MainSectionHeaderSubTitle;
