import styled from "styled-components";
import { Device } from "types";

const MainSectionHeaderTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};

  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  font-size: 26px;
  line-height: 26px;

  .br-1,
  .br-3 {
    display: initial;
  }

  @media (max-width: ${Device.mobileS}) {
    font-size: 22px;
    line-height: 22px;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 31px;
    line-height: 31px;
    .br-1,
    .br-3 {
      display: initial;
    }
  }

  @media (min-width: ${Device.tablet}) {
    font-size: 31px;
    line-height: 1.4;
    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 42px;

    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktopL}) {
    font-size: 50px;

    .br-1,
    .br-3 {
      display: none;
    }
  }

  @media (min-width: ${Device.desktopXL}) {
    font-size: 56px;

    .br-1,
    .br-3 {
      display: none;
    }
  }
`;

export default MainSectionHeaderTitle;
