import { importAllImages } from "utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const MebelLoftImagesHome = {
  home: images["mebel-loft-home-small.jpg"],
  placeholder: images["mebel-loft-home-small-placeholder.jpg"],
};

export const MebelLoftImages = [
  {
    name: "Обеденный стол в стиле Loft",
    small: images["mebel-loft-1-small.jpg"],
    high: images["mebel-loft-1-high.jpg"],
  },
  {
    name: "Cтол в стиле Loft",
    small: images["mebel-loft-2-small.jpg"],
    high: images["mebel-loft-2-high.jpg"],
  },
  {
    name: "Кровать в стиле Loft",
    small: images["mebel-loft-5-small.jpg"],
    high: images["mebel-loft-5-high.jpg"],
  },
  {
    name: "Кровати в стиле Loft",
    small: images["mebel-loft-4-small.jpg"],
    high: images["mebel-loft-4-high.jpg"],
  },
  {
    name: "Кровать в стиле Loft",
    small: images["mebel-loft-3-small.jpg"],
    high: images["mebel-loft-3-high.jpg"],
    width: "900",
    height: "1200",
  },
  {
    name: "Стелаж в стиле Loft",
    small: images["mebel-loft-6-small.jpg"],
    high: images["mebel-loft-6-high.jpg"],
    width: "900",
    height: "1200",
  },
  {
    name: "Стелаж в стиле Loft",
    small: images["mebel-loft-7-small.jpg"],
    high: images["mebel-loft-7-high.jpg"],
    width: "900",
    height: "1200",
  },
];
