import styled from "styled-components";
import { Device } from "types";
import { Home1920, Home1220, Home640, Home920 } from "assets";

const MainSectionHeader = styled.header`
  height: 45vh;
  background-image: url(${Home1920});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  background-color: ${({ theme }) => theme.colors.navbar};

  @media (max-width: ${Device.desktopL}) {
    background-image: url(${Home1220});
  }

  @media (max-width: ${Device.desktop}) {
    background-image: url(${Home920});
  }

  @media (max-width: ${Device.tablet}) {
    background-image: url(${Home640});
  }

  @media (min-width: ${Device.mobile}) {
    height: 65vh;
  }
`;

export default MainSectionHeader;
