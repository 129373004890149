import { v4 as uuidv4 } from "uuid";

import {
  BicycleParkingImagesHome,
  FencesImagesHome,
  StairsImagesHome,
  ShedsImagesHome,
  TurningWorkImagesHome,
  ForgingImagesHome,
  GarbageAreasImagesHome,
  GatesImagesHome,
  GardenProductsHome,
  MebelLoftImagesHome,
  IndustrialProductionHome,
  OthersImagesHome,
  LaserMetalCuttingImagesHome,
} from "assets/services";

import {
  BicycleParkingContent,
  FencesContent,
  StairsContent,
  ShedsContent,
  TurningWorkContent,
  ForgingContent,
  GarbageAreasContent,
  GatesContent,
  GardenProductsContent,
  MebelLoftContent,
  IndustrialProductionContent,
  OthersContent,
  LaserMetalCuttingContent,
} from "./cartContent";

type Service = {
  id: string;
  route?: string;
  title: string;
  imgSrc: string;
  placeholder: string;
  badge?: string;
  priority?: number;
  content: React.JSX.Element;
};

const servicesList = [
  {
    route: "/services/fences",
    title: "Заборы, ограждения",
    imgSrc: FencesImagesHome.home,
    placeholder: FencesImagesHome.placeholder,
    content: <FencesContent />,
  },
  {
    route: "/services/stairs",
    title: "Лестницы, перилла",
    imgSrc: StairsImagesHome.home,
    placeholder: StairsImagesHome.placeholder,
    content: <StairsContent />,
  },
  {
    route: "/services/sheds",
    title: "Навесы",
    imgSrc: ShedsImagesHome.home,
    placeholder: ShedsImagesHome.placeholder,
    content: <ShedsContent />,
  },
  {
    route: "/services/garbage-areas",
    title: "Крытые мусорные площадки",
    imgSrc: GarbageAreasImagesHome.home,
    placeholder: GarbageAreasImagesHome.placeholder,
    content: <GarbageAreasContent />,
  },
  {
    route: "/services/gates",
    title: "Ворота, калитки",
    imgSrc: GatesImagesHome.home,
    placeholder: GatesImagesHome.placeholder,
    content: <GatesContent />,
  },
  {
    route: "/services/bicycle-parking",
    title: "Велопарковки",
    imgSrc: BicycleParkingImagesHome.home,
    placeholder: BicycleParkingImagesHome.placeholder,
    content: <BicycleParkingContent />,
  },
  {
    route: "/services/mebel-loft",
    title: "Мебель в стиле Loft",
    imgSrc: MebelLoftImagesHome.home,
    placeholder: MebelLoftImagesHome.placeholder,
    content: <MebelLoftContent />,
  },
  {
    route: "/services/garden-and-cottage-products",
    title: "Изделия для сада и дачи",
    imgSrc: GardenProductsHome.home,
    placeholder: GardenProductsHome.placeholder,
    content: <GardenProductsContent />,
  },
  {
    route: "/services/industrial-production",
    title: "Изделия для промышленного производства",
    imgSrc: IndustrialProductionHome.home,
    placeholder: IndustrialProductionHome.placeholder,
    content: <IndustrialProductionContent />,
  },
  {
    title: "Токарные работы",
    imgSrc: TurningWorkImagesHome.home,
    placeholder: TurningWorkImagesHome.placeholder,
    content: <TurningWorkContent />,
  },
  {
    title: "Ковка",
    imgSrc: ForgingImagesHome.home,
    placeholder: ForgingImagesHome.placeholder,
    content: <ForgingContent />,
  },
  {
    title: "Лазерная резка металла",
    imgSrc: LaserMetalCuttingImagesHome.home,
    placeholder: LaserMetalCuttingImagesHome.placeholder,
    badge: "Новая услуга",
    priority: 10,
    content: <LaserMetalCuttingContent />,
  },
  {
    title: "Прочие услуги",
    imgSrc: OthersImagesHome.home,
    placeholder: OthersImagesHome.placeholder,
    content: <OthersContent />,
  },
];

const services: Service[] = servicesList.map((x) => ({
  id: uuidv4(),
  ...x,
}));

export default services;
