import styled from "styled-components";

const FooterWrapper = styled.div.attrs({
  className: "pt-3",
})`
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 332px;
`;

export default FooterWrapper;
