import styled from "styled-components";
import { IconMts } from "assets";
import { firmData } from "data";

const StyledImage = styled.img`
  margin-right: 5px;
`;

const LogoMtsImage = () => {
  return <StyledImage alt={firmData.phoneOperator} width={26} height={26} src={IconMts} />;
};

export default LogoMtsImage;
