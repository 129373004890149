import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { GardenProductsImages } from "assets/services";

const GardenProducts = () => {
  return (
    <ServicesChildPage
      title="Изделия для сада и дачи"
      description="Изделия для сада и дачи. Мангалы, гамаки, садовые качели, беседки, лавочки и др."
      images={GardenProductsImages}
      keywords="мангал, купить мангал, мангал брест, мангалы из металла, беседка, беседки брест, беседки недорого, лавочка, садовые качели купить, садовые качели, гамак"
    />
  );
};

export default GardenProducts;
