import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { MebelLoftImages } from "assets/services";

const MebelLoft = () => {
  return (
    <ServicesChildPage
      title="Мебель в стиле Loft"
      description="Изготовление, монтаж и покраска мебели в стиле LOFT. Столы и обеденные группы. Стеллажи и полки для производства и для дома. Вешалки для магазина одежды. Кровати с кованными элементами."
      images={MebelLoftImages}
      keywords="мебель loft, мебель лофт, мебель лофт купить, лофт, лофт брест, стиль лофт, столик лофт, стол лофт, лофт стиль, лофт металл, купить лофт, стулья лофт, полки лофт, кровать лофт"
    />
  );
};

export default MebelLoft;
