const workSteps = [
    { number: 1, text: "Вам нужна наша помощь – и вы звоните нам!" },
    { number: 2, text: "Этап замеров, консультации, согласования цены и сроков исполнения заказа." },
    { number: 3, text: "Оформление и подписание договора, внесение предоплаты. Cумма предоплаты фиксируется в договоре." },
    { number: 4, text: "Этап выполнения заказа, доставка и монтаж (при необходимости)." },
    { number: 5, text: "Подписание акта о приемке выполненных работ заказчиком." },
    { number: 6, text: "Окончательная оплата заказа." },
    { number: 7, text: "Соблюдение гарантийных обязательств." },
    { number: 8, text: "При наличии нового заказа вы опять звоните нам!" },
];

export default workSteps;