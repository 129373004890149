import { Container, Row } from "react-bootstrap";
import { ChildPage } from "components";
import ServiceInfoCard from "pages/services/card/ServiceInfoCard";
import services from "./servicesData";

const sortedServices = [...services].sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));

const ServicesPage = () => (
  <ChildPage title="Услуги">
    <Container>
      <Row>
        {sortedServices.map(({ id, content, ...props }) => (
          <ServiceInfoCard key={id} {...props}>
            {content}
          </ServiceInfoCard>
        ))}
      </Row>
    </Container>
  </ChildPage>
);

export default ServicesPage;
