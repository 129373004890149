import { Helmet } from "react-helmet-async";

type BasePageProps = {
  title: string;
  description?: string;
  keywords?: string;
  children?: React.ReactNode;
}

const Page = ({ title, description, keywords, children }: BasePageProps) => {
  const href = window.location.href;
  const documentTitle = `${title} - Сварочные работы в Бресте и Брестской области`;

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
        <link rel="canonical" href={href} />
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
      {children}
    </>
  );
};

export default Page;
