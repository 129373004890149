import { Container, Row, Col } from "react-bootstrap";
import { PrimaryButton, Line, PrimaryLink } from "components";
import { useCheckMobileDevice } from "hooks";
import { Welder640 } from "assets";
import { firmLinks } from "data";;

const AboutUsSection = () => {
  const isMobile = useCheckMobileDevice();

  return (
    <section id="about_us" className="pt-4 pb-4">
      <Container>
        <Row className="justify-content-center">
          <Col xl={6} lg={8}>
            <div className="text-center">
              <h2 className="fw-bold">О нас</h2>
              <Line />
            </div>
          </Col>
        </Row>
        <Row className="align-items-center pb-4">
          <Col lg={6}>
            <div className="text-center text-sm-start">
              <div>
                <p className="fw-bold">
                  Мы оказываем профессиональные услуги сварочных работ в Бресте и Брестской области.
                </p>
                <p>
                  Большой опыт работы, более 10 лет, и собственное производство позволяет выполнять
                  большой спектр услуг.
                </p>
                <p>
                  Мы можем предложить вам готовую продукцию, а также выполним любой заказ по вашим
                  чертежам.
                </p>
                <p>
                  Мы работаем как с физическим лицом, так и с компаниями, которым готовы предложить
                  свою продукцию в промышленных масштабах.
                </p>
                <p>
                  Также мы можем изготовить оборудование для вашего производства.
                  <PrimaryLink to="/partnership"> Подробнее.</PrimaryLink>
                </p>
                <p>
                  Работаем только с качественными материалами с использованием профессионального
                  электротехнического инструмента с соблюдением требований безопасности.
                </p>
                <p className="fw-bold">
                  Выбирая нас, вы можете быть уверены, что доверяете свой заказ профессионалам!
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} className="d-none d-sm-block">
            <img className="img-fluid" src={Welder640} alt="О нас" />
          </Col>
          <Col lg={6} className="text-center pt-3 pt-sm-4 pt-lg-3">
            {isMobile && <PrimaryButton href={firmLinks.telHref}>Связаться с нами</PrimaryButton>}
            {!isMobile && <PrimaryButton to="/contacts">Связаться с нами</PrimaryButton>}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUsSection;
