import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "types";
import { useCheckTabletDevice } from "hooks";
import BenefitCardIconWrapper from "./BenefitCardIconWrapper";
import BenefitCardIcon from "./BenefitCardIcon";
import BenefitCardTitle from "./BenefitCardTitle";
import BenefitCardContent from "./BenefitCardContent";
import BenefitsCardContainer from "./BenefitsCardContainer";

const themes = {
  light: {
    textColor: Theme.colors.black,
    iconColor: Theme.colors.primary,
    cardColor: Theme.colors.white,
  },
  dark: {
    textColor: Theme.colors.white,
    iconColor: Theme.colors.white,
    cardColor: Theme.colors.primary,
  },
};

type BenefitCardProps = {
  theme: keyof typeof themes;
  icon: any;
  title: string;
  children: React.ReactNode;
}

const BenefitCard = ({ theme, icon, title, children }: BenefitCardProps) => {
  const [hovered, setHovered] = useState(false);
  const isTablet = useCheckTabletDevice();
  const cardTheme = themes[theme];

  const toggleHover = () => setHovered(prevHovered => !prevHovered);

  return (
    <BenefitsCardContainer
      cardColor={cardTheme.cardColor}
      textColor={cardTheme.textColor}
      className="p-4 text-center"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <BenefitCardIconWrapper className="text-center">
        <BenefitCardIcon iconColor={cardTheme.iconColor}>
          <FontAwesomeIcon icon={icon} className={hovered && !isTablet ? "fa-beat-fade" : ""} />
        </BenefitCardIcon>
      </BenefitCardIconWrapper>
      <BenefitCardTitle textColor={cardTheme.textColor}>{title}</BenefitCardTitle>
      <BenefitCardContent textColor={cardTheme.textColor}>{children}</BenefitCardContent>
    </BenefitsCardContainer>
  );
};

export default BenefitCard;
