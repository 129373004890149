import { Container, Navbar, Nav } from "react-bootstrap";
import { Logo } from "components";
import HeaderNavLink from "./HeaderNavLink";
import HeaderNavbar from "./HeaderNavbar";
import { NavLinksContainer } from "components";

const Header = () => {
  return (
    <HeaderNavbar collapseOnSelect expand="lg" className="navigation" variant="dark">
      <Container>
        <Logo />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" role="navigation">
          <Nav className="me-auto" />
          <Nav>
            <NavLinksContainer navlink={HeaderNavLink} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </HeaderNavbar>
  );
};

export default Header;
