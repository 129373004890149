import styled from "styled-components";
import { Nav } from "react-bootstrap";

const FooterNavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.white} !important;

  &.nav-link.active_link {
    color: ${({ theme }) => theme.colors.primaryYellow} !important;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primaryYellow} !important;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

export default FooterNavLink;
