import { ChildPage, ServiceGallery, } from "components";
import { ChildPageProps } from "components/childPage/ChildPage";

type ServiceDetailsPageProps = ChildPageProps & {
  images: {
    high: string;
    small: string;
    name: string;
    width?: string;
    height?: string
  }[];
}

const ServiceDetailsPage = ({ images, ...props }: ServiceDetailsPageProps) => {

  const galleryImages = images.map((item) => {
    return {
      original: item.high,
      thumbnail: item.small,
      alt: item.name,
      src: item.small,
      width: item.width ?? "1600",
      height: item.height ?? "900",
    };
  });

  return (
    <ChildPage {...props}>
      <ServiceGallery images={galleryImages}></ServiceGallery>
    </ChildPage>
  );
};

export default ServiceDetailsPage;
