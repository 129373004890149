import ServiceDetailsPage from "./ServiceDetailsPage";
import { BicycleParkingImages } from "assets/services";

const BicycleParking = () => {
  return (
    <ServiceDetailsPage
      title="Велопарковки"
      description="Велопарковки: крытые, отдельно стоящие, П-образные, треугольные с навесом. Использование различных материалов для стен: дерево, камень, профнастил, жалюзи и др. 3D проект."
      images={BicycleParkingImages}
      keywords="велопарковка, велопарковка купить, велопарковки нержавеющие, крытая велопарковка, велопарковка навес, велопарковка крытая крыло, велопарковка из стали, велопарковка цена"
    />
  );
};

export default BicycleParking;
