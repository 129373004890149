import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { FencesImages } from "assets/services";

const Fences = () => {
  return (
    <ServicesChildPage
      title="Заборы, Ограждения"
      description="Заборы любой сложности, газонные ограждения, кованные заборы, 3D заборы."
      keywords="забор, ограждение, забор брест, забор кобрин, забор цена, распашные ворота, газонное ограждение, откатные ворота, 3d забор, гаражные ворота, кованные ворота, купить ограждения, купить ворота, ворота брест, металлический забор, забор жалюзи"
      images={FencesImages}
    />
  );
};

export default Fences;
