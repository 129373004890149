const firmData = {
  siteName: "Сварка-Брест.бай",
  phoneNumberFormatted: "+375 (29) 806-79-09",
  phoneNumber: "+375298067909",
  phoneOperator: "МТС",
  id: 291539435,
  name: "ИП Вишневецкий С.Н.",
  description: "Сварочные работы любой сложности в Бресте и Брестской области. Широкий выбор услуг. Гарантия качества и доступные цены. Собственное производство.",
  instagram: "https://www.instagram.com/svarka_brest.by/",
  email: "business@svarka-brest.by",
  address: "г. Брест ул. Фортечная, 63Б/5, 224024",
  mapUri: "https://yandex.by/maps/-/CCU7aWXFwC",
  copyright: "© 2025 ИП Вишневецкий С.Н.",
  mapFrameUri: "https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=184960985006"
}

export default firmData;