import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { IndustrialProductionImages } from "assets/services";

const IndustrialProduction = () => {
  return (
    <ServicesChildPage
      title="Изделия для производства"
      description="Сварка и ремонт промышленного оборудования для производства. Изготовление ферм, конвейерных линий, емкостей. Прокладка магистрали из черного металла и нержавеющей стали высокого давления."
      images={IndustrialProductionImages}
      keywords="конвейерная линия, ферма, производство, токарные работы, токарные работы брест, кованые изделия, промышленное оборудование, ремонт промышленного оборудования, ремонт и обслуживание промышленного оборудования"
    />
  );
};

export default IndustrialProduction;
