import { importAllImages } from "utils";

const images = importAllImages(require.context("./", false, /\.(png|jpe?g|svg)$/));

export const FencesImagesHome = {
  home: images["fence-4-small.jpg"],
  placeholder: images["fence-4-small-placeholder.jpg"],
};

export const FencesImages = [
  {
    name: "Газонное ограждение",
    small: images["fence-1-small.jpg"],
    high: images["fence-1-high.jpg"],
  },
  {
    name: "Газонное ограждение",
    small: images["fence-2-small.jpg"],
    high: images["fence-2-high.jpg"],
  },
  {
    name: "Металлический забор с кованными элементами",
    small: images["fence-4-small.jpg"],
    high: images["fence-4-high.jpg"],
  },
  {
    name: "Газонное ограждения из металла",
    small: images["fence-6-small.jpg"],
    high: images["fence-6-high.jpg"],
  },
  {
    name: "Решётки над входом в подвал",
    small: images["fence-7-small.jpg"],
    high: images["fence-7-high.jpg"],
    width: "1197",
    height: "1600",
  },
  {
    name: "Решётки над входом в подвал",
    small: images["fence-8-small.jpg"],
    high: images["fence-8-high.jpg"],
    width: "1077",
    height: "1440",
  },
  {
    name: "Решётки над входом в подвал",
    small: images["fence-9-small.jpg"],
    high: images["fence-9-high.jpg"],
    width: "1077",
    height: "1440",
  },
  {
    name: "Решётки над входом в подвал",
    small: images["fence-10-small.jpg"],
    high: images["fence-10-high.jpg"],
    width: "1077",
    height: "1440",
  },
];
