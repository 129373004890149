import { Container, Col, Nav } from "react-bootstrap";
import FooterContainer from "./FooterContainer";
import FooterSocialIconsContainer from "./FooterSocialIconsContainer";
import FooterContactInfoContainer from "./FooterContactInfoContainer";
import FooterWrapper from "./FooterWrapper";
import FooterRow from "./FooterRow";
import FooterNavLink from "./FooterNavLink";
import { firmData } from "data";
import { NavLinksContainer } from "components";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <Container className="text-white bottom_border">
          <FooterRow className="pt-3">
            <Col sm={6} md={4}>
              <h5>{firmData.name}</h5>
              <p>
                {firmData.description}
                <br />
                УНП: {firmData.id}.
              </p>
              <FooterSocialIconsContainer />
            </Col>
            <Col sm={6} md={4} className="mt-2 mt-sm-0">
              <FooterContactInfoContainer />
            </Col>
            <Col sm={12} md={4} className="mt-2 mt-md-0">
              <Nav className="flex-md-column justify-content-center">
                <NavLinksContainer navlink={FooterNavLink} />
              </Nav>
            </Col>
            <p className="mt-2 mt-sm-0 text-white text-center">От вас идея - от нас результат!</p>
          </FooterRow>
          <p className="text-white text-center">
            {firmData.copyright}
            <br className="d-sm-none" /> Все права защищены.
          </p>
        </Container>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
