import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { ViberIcon, ChildPage, StyledLink } from "components";
import { faUser, faMapMarkerAlt, faEnvelope, faPhone, faClock } from "@fortawesome/free-solid-svg-icons";
import Address from "./Address";
import MapFrame from "./MapFrame";
import { firmData, firmLinks } from "data";

const StyledViberIcon = styled(ViberIcon)`
  margin-left: 3px;
`;

const ContactsPage = () => {

  return (
    <ChildPage title="Контакты">
      <Container fluid className="pb-4 pb-sm-2">
        <Row className="justify-content-center">
          <Col xl={4} lg={5}>
            <Address title="О нас" icon={faUser}>
              <div>
                ИП Вишневецкий <br className="d-block d-sm-none" /> Сергей Николаевич
              </div>
              <div>УНП: {firmData.id}</div>
            </Address>
            <Address title="Наш адрес" icon={faMapMarkerAlt} href={firmData.mapUri}>
              {firmData.address}
            </Address>
            <Address
              title="Электронный адрес"
              icon={faEnvelope}
              href={firmLinks.emailHref}
            >
              {firmData.email}
            </Address>
            <Address title="Контактный телефон" icon={faPhone} href={firmLinks.telHref}>
              <StyledLink color="black" href={firmLinks.telHref} target="_blank">
                {firmData.phoneNumberFormatted}
              </StyledLink>
              <StyledViberIcon viberHref={firmLinks.viberHref} />
            </Address>
            <Address title="Время работы" icon={faClock}>
              Понедельник - Воскресенье: <br /> 09.00 - 21.00.
            </Address>
          </Col>
          <Col xl={6} lg={6}>
            <MapFrame />
          </Col>
        </Row>
      </Container>
    </ChildPage>
  );
};

export default ContactsPage;
