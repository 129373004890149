type Images = { [key: string]: string };

const importAllImages = (r: any): Images => {
  const images: Images = {};
  r.keys().forEach((item: string) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export default importAllImages;
