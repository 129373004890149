import { default as ServicesChildPage } from "./ServiceDetailsPage";
import { GatesImages } from "assets/services";

const Gates = () => {
  return (
    <ServicesChildPage
      title="Ворота, калитки"
      description="Изготовление, монтаж и покраска всех видов ворот (распашные, откатные, гаражные, кованные) и калиток. Использование различных материалов: профильная труба, профнастил, металлический штакетник и др."
      images={GatesImages}
      keywords="откатные ворота, ворота в гараж, гаражные ворота, ворота откатные, кованные ворота, купить ворота, ворота брест, роллетные ворота, монтаж ворот, калитки, замок для калитки, кованные калитки, кованые калитки, ворота и калитки для дачи, калитки брест"
    />
  );
};

export default Gates;
