const GarbageAreasContent = () => (
  <ul>
    <li>Изготовление мусорных площадок открытого и закрытого типа «под ключ»</li>
    <li>Возможность установки кодового замка</li>
    <li>
      Использование различных материалов для стен площадки: дерево, камень, профнастил,
      жалюзи и др.
    </li>
    <li>Создание 3D проекта</li>
  </ul>
);

export default GarbageAreasContent;
