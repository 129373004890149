import { HTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterSocialIcon from "./FooterSocialIcon";
import { faViber, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { firmData, firmLinks } from "data";;

const socialIcons = [
  {
    href: firmLinks.viberHref,
    icon: faViber,
  },
  {
    href: firmData.instagram,
    icon: faInstagram,
  },
];

type FooterSocialIconsContainerProps = HTMLAttributes<HTMLUListElement>;

const FooterSocialIconsContainer = ({ ...props }: FooterSocialIconsContainerProps) => (
  <ul className="list-inline mt-3" {...props}>
    {socialIcons.map(({ href, icon }) => (
      <li className="list-inline-item" key={href}>
        <FooterSocialIcon href={href} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={icon} />
        </FooterSocialIcon>
      </li>
    ))}
  </ul>
);

export default FooterSocialIconsContainer;
