const ShedsContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска:
      <ul>
        <li>навесов любой сложности</li>
        <li>навесов для авто</li>
      </ul>
    </li>
    <li>
      Использование материала на выбор заказчика: поликарбонат, профнастил,
      металлочерепица
    </li>
  </ul>
);

export default ShedsContent;
