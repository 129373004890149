const LaserMetalCuttingContent = () => (
  <ul>
    <li>Высокоточная резка металла по чертежам заказчика</li>
    <li>Создание сложных и детализированных форм</li>
    <li>Работа с различными видами металлов (сталь, алюминий, медь и др.)</li>
    <li>Возможность серийного производства и индивидуальных заказов</li>
  </ul>
);

export default LaserMetalCuttingContent;
