import { Container, Row, Col } from "react-bootstrap";
import { List, ListItem, Line, ChildPage } from "components";
import { Handshake640 } from "assets";

const partnershipServices = [
  { number: 1, service: "Cварка промышленного оборудования для производства" },
  { number: 2, service: "Ремонт промышленного оборудования" },
  { number: 3, service: "Изготовление ферм" },
  { number: 4, service: "Изготовление емкостей" },
  { number: 5, service: "Изготовление конвейерных линий" },
  { number: 6, service: "Прокладка магистрали из черного металла и нержавеющей стали высокого давления" },
  { number: 7, service: "Tокарные работы" },
  { number: 8, service: "Изготовление кованных изделий" }
];

const Partnership = () => {
  return (
    <ChildPage
      title="Cотрудничество"
      keywords="сварка, ремонт, промышленное оборудование, фермы, конвейерные линии, емкости, магистрали, токарные работы, кованные изделия"
      description="Сварка и ремонт промышленного оборудования для производства. Изготовление ферм, конвейерных линий, емкостей. Прокладка магистрали из черного металла и нержавеющей стали высокого давления."
    >
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col xl={6} lg={8}>
              <div className="text-center">
                <h2 className="fw-bold">
                  Мы можем предложить следующие услуги для вашего производства:
                </h2>
                <Line />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6} className="pb-4">
              <List>
                {partnershipServices.map(({ number, service }) => (
                  <ListItem key={number} number={number}>{service}</ListItem>
                ))}
              </List>
            </Col>
            <Col lg={6}>
              <img className="img-fluid" src={Handshake640} alt="Сотрудничество" />
            </Col>
          </Row>
          <Row className="pb-2 pt-4 pt-lg-2">
            <h2 className="text-center fw-bold">Будем рады сотрудничеству!</h2>
          </Row>
        </Container>
      </section>
    </ChildPage>
  );
};

export default Partnership;
