import { Container } from "react-bootstrap";
import { PrimaryButton, ChildPage } from "components";

const NotFoundPage = () => {
  return (
    <ChildPage
      title="Ошибка 404. Страница не найдена"
      description="Ошибка 404. Страница не найдена"
      hideHeader
    >
      <Container>
        <div className="d-flex align-items-center justify-content-center my-3">
          <div className="text-center">
            <h1 className="display-3 fw-bold">Страница не найдена</h1>
            <p className="lead">
              Вы находитесь здесь потому, что запрашиваемая страница не существует или была
              перемещена по другому адресу.
              <br />
              Пожалуйста, воспользуйтесь навигацией, чтобы найти интересующую вас информацию.
            </p>
            <PrimaryButton to="/">Перейти на главную</PrimaryButton>
          </div>
        </div>
      </Container>
    </ChildPage>
  );
};

export default NotFoundPage;
