import styled from "styled-components";
import { Device } from "types";

const LogoTitle = styled.h4.attrs({
  className: "navbar-title",
})`
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: ${Device.mobile}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: ${Device.desktop}) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export default LogoTitle;
