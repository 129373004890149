import styled from "styled-components";

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.navbar};
  min-height: 250px;
  padding: 10px 0px 5px 0px;
  color: #fff;
`;

export default FooterContainer;
