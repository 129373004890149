const Device = {
  mobileS: "320px",
  mobile: "425px",
  tablet: "768px",
  desktop: "992px",
  desktopL: "1200px",
  desktopXL: "1400px",
};

export default Device;
