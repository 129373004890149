import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
  colors:{
    primaryYellow: "#ffc107",
    primary: "#1a555c",
    white: "white",
    black: "#212529",
    navbar: "#2e2c2e",
    socialIcon: "#545454",
    grey: "#f6f6f6",
  },

};

export default Theme;