import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Device } from "types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const AddressLine = styled.div`
  overflow: hidden;
  padding-left: 40px;

  @media (max-width: ${Device.desktopXL}) {
    padding-left: 0px;
  }
`;

const AddressIcon = styled.a.attrs({
  className: "rounded-circle",
})`
  background: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.black};
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  float: left;
  margin-right: 14px;
  font-size: 22px;

  :hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.primaryYellow};
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      background: ${({ theme }) => theme.colors.grey};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

const AddressTitle = styled.h4`
  font-size: 18px;
  overflow: hidden;
  font-weight: 600;
`;

const AddressBlock = styled.div`
  overflow: hidden;
  margin-top: 5px;
`;

type AddressProps = {
  href?: string;
  icon: IconProp;
  title: string;
  children: React.ReactNode;
}

const Address = ({ href, icon, title, children }: AddressProps) => {
  const linkProps = href ? { href, target: "_blank" } : {};

  return (
    <AddressLine className="mb-4 d-flex align-items-center mb-2">
      <div className="flex-shrink-0">
        <AddressIcon {...linkProps}>
          <FontAwesomeIcon icon={icon} />
        </AddressIcon>
      </div>
      <div className="flex-grow-1">
        <AddressTitle>{title}</AddressTitle>
        <AddressBlock>{children}</AddressBlock>
      </div>
    </AddressLine>
  );
};

export default Address;
