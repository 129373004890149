type ContactType = 'telephone' | 'viber' | 'email';

const buildHref = (type: ContactType, value: string): string => {
    switch (type) {
        case 'telephone':
            return `tel:${value}`;
        case 'viber':
            return `viber://chat?number=${encodeURIComponent(value)}`;
        case 'email':
            return `mailto:${value}`;
        default:
            throw new Error('Invalid contact type');
    }
};

export default buildHref;