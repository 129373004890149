import { useEffect, useState } from "react";

const getMatches = (query: string) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }
  return false;
};

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean>(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    setMatches(getMatches(query));

    if (matchMedia.addListener) {
      matchMedia.addListener(() => setMatches(getMatches(query)));
    } else {
      matchMedia.addEventListener("change", () => setMatches(getMatches(query)));
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(() => setMatches(getMatches(query)));
      } else {
        matchMedia.removeEventListener("change", () => setMatches(getMatches(query)));
      }
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
