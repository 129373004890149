import { StyledLink } from "components";
import LogoTitle from "./LogoTitle";
import LogoSubtitle from "./LogoSubtitle";
import LogoContactsContainer from "./LogoContactsContainer";
import LogoPhoneOperatorImage from "./LogoPhoneOperatorImage";
import LogoViberIcon from "./LogoViberIcon";
import { firmData, firmLinks } from "data";;

const Logo = () => {
  return (
    <div className="navbar-brand">
      <StyledLink color="white" to="/">
        <LogoTitle>{firmData.siteName}</LogoTitle>
      </StyledLink>
      <LogoContactsContainer>
        <StyledLink
          color="white"
          href={firmLinks.telHref}
          target="_blank"
          rel="noreferrer"
        >
          <LogoPhoneOperatorImage />
          <LogoSubtitle>{firmData.phoneNumberFormatted}</LogoSubtitle>
        </StyledLink>
        <LogoViberIcon viberHref={firmLinks.viberHref} />
      </LogoContactsContainer>
    </div>
  );
};

export default Logo;
