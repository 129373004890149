import styled from "styled-components";
import { Link } from "react-router-dom";

const PrimaryLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryYellow};
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme.colors.primaryYellow};
    font-weight: bold;
    text-decoration: none;
  }
`;

export default PrimaryLink;