import styled from "styled-components";
import { HTMLAttributes } from "react";

const StyledDiv = styled.div`
  height: 2px;
  margin: 20px auto 20px;
  position: relative;
  width: 80px;
  background: ${({ theme }) => theme.colors.primary};
`;

type LineProps = HTMLAttributes<HTMLDivElement>

const Line = ({ children, ...props }: LineProps) => {
  return (
    <StyledDiv {...props} className="border">
      {children}
    </StyledDiv>
  );
};

export default Line;
