import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import { routes } from "data";

const navLinks = routes
  .filter((route) => route.showInNavbar)
  .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

type NavLinksContainerProps = {
  navlink: typeof Nav.Link;
}

const NavLinksContainer = ({ navlink }: NavLinksContainerProps) => {
  const NavLink = navlink;

  return (
    <>
      {navLinks.map(({ path, label }) => (
        <LinkContainer key={path} to={path} activeClassName="active_link">
          <NavLink eventKey={path}>{label}</NavLink>
        </LinkContainer>
      ))}
    </>
  );
};

export default NavLinksContainer;
