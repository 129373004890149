const StairsContent = () => (
  <ul>
    <li>
      Изготовление, монтаж и покраска:
      <ul>
        <li>лестниц и перил любой сложности</li>
        <li>кованных изделий для декора лестниц и перилл</li>
      </ul>
    </li>
    <li>Создание индивидуального проекта </li>
  </ul>
);

export default StairsContent;
